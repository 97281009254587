// Perhaps these classes should be removed.
.app {
  height: 100%;
}

.main {
  display: flex;
  height: 100%;
}

.main--primary {
  padding-top: 5rem;
}
// Perhaps these classes should be removed. ./end

// Variables
@import 'assets/scss/settings/settings.variables';

// Vendor
@import 'react-datepicker/src/stylesheets/datepicker';

// Geoapify
@import 'assets/scss/components/components.geoapify';

// Components
@import 'assets/scss/components/components.datepicker';

.map-container {
  width: calc(100vw - 250px);
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.maplibregl-popup-content {
  padding: 0;
  margin-bottom: 10px;
  background-color: transparent;
}

.maplibregl-popup-tip {
  display: none;
}

.maplibregl-popup-close-button {
  top: unset;
  bottom: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px 4px 0 0;
  color: #fff;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

span[class^="PrivateValueLabel-circle"] {
  border-radius: 4px;
}

@media only screen and (max-width: 959.95px) {
  .map-container {
    width: 100%;
  }
}

.ol-overlaycontainer-stopevent {
  z-index: 1001 !important;
}
